import { ref, watch, getCurrentInstance } from '@vue/composition-api';
/**
 * Check if the Storyblok bridge exists in the DOM
 */
const checkStoryblokBridgeExists = () => {
    const existingScript = document.getElementById('storyblokBridge');
    return !!existingScript;
};
/**
 * Build the Storyblok bridge by adding the script to the DOM and calling the given callback.
 * If the bridge already exists, the callback is called immediately.
 * @param callback
 */
const buildBridge = (callback) => {
    const bridgeExists = checkStoryblokBridgeExists();
    if (!bridgeExists) {
        const script = document.createElement('script');
        script.src = '//app.storyblok.com/f/storyblok-v2-latest.js';
        script.id = 'storyblokBridge';
        document.head.appendChild(script);
        script.onload = () => {
            callback();
        };
    }
    else {
        callback();
    }
};
export function useStoryblokBridge(story) {
    const instance = getCurrentInstance();
    const bridge = ref(null);
    const isInEditor = ref(false);
    buildBridge(() => {
        const { StoryblokBridge } = window;
        bridge.value = new StoryblokBridge();
    });
    /**
     * Watch the bridge value and call pingEditor to see if the user is in the editor
     */
    watch(() => bridge.value, () => {
        if (bridge.value) {
            // Call pingEditor to see if the user is in the editor
            // https://www.storyblok.com/docs/Guides/storyblok-latest-js
            bridge.value.pingEditor(() => {
                isInEditor.value = bridge.value.isInEditor();
            });
        }
    });
    /**
     * Watch the isInEditor value and listen for input events
     */
    watch(() => isInEditor.value, () => {
        if (!isInEditor.value || !instance)
            return;
        /**
         * Listen for input events and update the story on the page if the event is for the current story
         */
        bridge.value.on('input', (event) => {
            if (event.story.content._uid === story.value.content._uid) {
                story.value = event.story;
            }
        });
        /**
         * Listen for publish events and refetch the story from backend with a delay of 2 seconds.
         * This is to ensure that the published story is available from the server when the event is emitted.
         */
        bridge.value.on('published', (event) => {
            setTimeout(() => {
                instance.$emit('storyPublished', event);
            }, 2000);
        });
    });
    return {};
}
