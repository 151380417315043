import { defineComponent, computed, ref, watch, reactive, getCurrentInstance } from '@vue/composition-api';
import BackButton from '@/components/BackButton.vue';
import TrustPublication from '@/components/TrustPublication.vue';
import HelpdeskFooter from '@/Helpdesk/components/HelpdeskFooter.vue';
import { useGetLocalizedPath } from '@/util/globalHelpers';
import { HelpdeskRoutes } from '@/Helpdesk/helpdesk.const';
import { useGetHelpdeskStoryQuery } from '@/generated-types/graphql.types';
import { useMeta } from '@/shared/composables/useMeta';
import { SITE_URL } from '@/App.vue';
import { useStoryblokBridge } from '@/shared/composables/useStoryblokBridge';
export default defineComponent({
    components: {
        BackButton,
        HelpdeskFooter,
        TrustPublication
    },
    setup(_, context) {
        const { root } = context;
        const instance = getCurrentInstance();
        const helpdeskCategorySlug = computed(() => root.$route.params.helpdeskCategorySlug);
        const helpdeskArticleSlug = computed(() => `${root.$route.params.helpdeskCategorySlug}/${root.$route.params.helpdeskArticleSlug}`);
        const article = ref(null);
        const preview = root.$route.query._storyblok_version === '';
        const queryVariables = reactive({
            slug: helpdeskArticleSlug.value,
            preview
        });
        const queryOptions = reactive({
            enabled: !!helpdeskArticleSlug.value
        });
        const { onResult, refetch } = useGetHelpdeskStoryQuery(queryVariables, queryOptions);
        onResult((result) => {
            if (!result?.data?.get_helpdesk_story)
                return;
            const data = JSON.parse(result.data.get_helpdesk_story.story);
            article.value = data?.story;
            console.log(article.value.full_slug);
            if (globalThis.$i18n.locale === 'en') {
                history.replaceState({}, null, `${SITE_URL}/${article.value.full_slug}`);
            }
            useMeta({
                title: article.value?.content?.metadata?.title,
                description: article.value?.content?.metadata?.description,
                url: `${SITE_URL}/${article.value.full_slug}`,
                noIndex: false
            });
        });
        /**
         * Watch for locale change and refetch the blog article content
         */
        watch(() => globalThis.$i18n.locale, () => {
            refetch();
        });
        watch(() => helpdeskArticleSlug.value, (newSlug, oldSlug) => {
            if (newSlug && newSlug !== oldSlug) {
                queryVariables.slug = newSlug;
                refetch();
            }
        }, { immediate: true });
        if (window.location.search.includes('_storyblok')) {
            // load the bridge only inside of Storyblok Editor
            useStoryblokBridge(article);
            instance.$on('storyPublished', refetch);
        }
        return {
            article,
            helpdeskCategorySlug,
            HelpdeskRoutes,
            useGetLocalizedPath
        };
    }
});
